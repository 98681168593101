html {
    overflow-x: hidden;
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #2c4547;
    margin: 0;
    padding: 0;
    font-size: .8em;
}

h1, .h1 {
    font-family: 'Playfair Bold';
    color: #fff;
    font-size: 1.85em;
}

h2, .h2 {
    font-family: 'Playfair Black';
    font-size: 1.7em;
    padding-left: 48px;
    color: #2c4547;
    font-weight: 400
}

h2::before, .h2::before {
    content: '';
    position: absolute;
    width: 43px;
    height: 10px;
    margin-top: 14px;
    margin-left: -48px;
    background: #2c4547;
}

h2 a, .h2 a {
    color: inherit;
    text-decoration: none;
}

h3 {
    font-family: 'Playfair Black';
}

.content-on-header h1,
.content-on-header .h1 {
    font-size: 1.9em;
}

h2.h2-home {
    font-family: 'Open Sans';
    font-size: 1em;
    text-transform: uppercase;
    color: #175c6b;
}

h2.h2-home::before {
    margin-top: 4px; 
    background: #175c6b;   
}

.activities h2 {
    color: #7c7c7c;
}

.activities h2::before {
    background: #7c7c7c;
}

.patient_path h2,
h3.northwood {
    color: #175c6b;
}

.patient_path h2::before {
    background: #175c6b;
}

.content-cheveux h2.ludwig,
h3.ludwig,
h2.consultations {
    color: #4190a1;
}

.content-cheveux h2.ludwig::before,
h2.consultations::before {
    background: #4190a1;
}

.post-patient_path-home h3 {
    font-size: 3em;
    line-height: 1.1em;
    opacity: .5;
    margin-bottom: 15px;
}

.post-activities-home h3 {
    padding-left: 28px;
    font-family: 'Playfair Bold';
}

.post-activities-home h3::before {
    content: '';
    position: absolute;
    width: 33px;
    height: 6px;
    margin-top: 10px;
    margin-left: -38px;
    background: #fff;
}

.slider-stade h3 {
    font-family: 'Playfair Bold';
}

.slider-stade h3::after {
    content: '';
    border-bottom-style: solid;
    border-bottom-width: 5px;
    display: table-cell;
    width: 30px;
}

.h3-home, h3.h3-contact {
    font-size: 1.7em;
    font-family: 'Playfair Bold';
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0.445em;
}

h2.h2-contact {
    color: #175c6b;
}

h2.h2-contact::before {
    background-color: #175c6b;
}

.h2.h2-contact::before {
    display: none
}

.header {
    background-color: #175c6b;
    color: #fff;
    overflow: hidden;
}

.header,
.header-content {
    height: 290px;
}

.header-picture {
    mix-blend-mode: multiply;
    overflow: hidden;
    height: 100%;
}

.header-picture-home {
    mix-blend-mode: soft-light;
}

.header-picture .header-picture-banner {
    height: 100%;
}

.header-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.header-home,
.header-content-home {
    height: 220px;
}

.content-on-header {
    background-color: rgba(14, 57, 66, 0.8);
}

.header-content .header-site_identity {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.content-page-header {
    text-transform: uppercase;
    font-family: 'Open Sans Light';
    font-size: .85em;
}

.content-page-header a {
    text-transform: uppercase;
    font-family: 'Open Sans Light';
    color: #ffffff;
    text-decoration: none;
    font-size: 1em;
}

.content-page-header strong {
    font-family: 'Open Sans SemiBold';
}

.content-all {
    background: url('../images/bg.png');
    background-size: 80%;
    margin: auto 15px;
    overflow: hidden;
}

.wp-block-quote {
    font-family: 'Playfair Black';
    font-size: 3em;
    margin: 0;
    text-transform: lowercase;
    width: 60%;
    line-height: 1em;
}

.wp-block-quote p {
    margin: 30px 0;
}

.post-patient_path-home {
    overflow: hidden;
    height: 300px;
    color: white;
    font-family: 'Open Sans Light';
    display: flex;
    justify-content: flex-end;
}

.post-patient_path-home-1 {
    background: rgb(112, 164, 46);
}

.post-patient_path-home-2 {
    background: rgb(48, 150, 159);
}

.post-patient_path-home-3 {
    background: rgb(68, 103, 106);
}

.post-patient_path-home-4 {
    background: rgb(177, 165, 31);
}

.post-patient_path-home .article-content {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-patient_path-home .preview,
.testimonials .preview {
    display: flex;
    flex-direction: column;
}

.post-patient_path-home .preview a,
.testimonials .preview a {
    background: rgba(34, 51, 53, .8);
    color: #fff;
    text-decoration: none;
    width: 95px;
    padding: 8px;
    border-radius: 1em;
    margin-top: 15px;
    transition: .3s all;
}

.post-patient_path-home .preview a:hover,
.testimonials .preview a:hover {
    background: rgba(25, 39, 41, 0.9);
}

.testimonials .preview a {
    align-self: flex-end;
    font-family: 'Open Sans Light';
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
}

.testimonials .preview {
    padding: 10px 10px 10px 20px;
    color: white;
    font-family: 'Playfair Bold'
}


.index-post-patient_path-home {
    position: absolute;
    margin-left: -120px;
    margin-top: -23px;
    font-size: 5em;
}

.thumbnail-patient-path {
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
    transition: .4s all;
}

.post-patient_path-home:hover .thumbnail-patient-path {
    transform: scale(1.1);
}

.activity-blocks-home {
    margin-top: -10px;
}

.post-activities-home .background-animate {
    position: absolute;
    bottom: 0;
    background: rgb(112, 164, 46);
    mix-blend-mode: multiply;
    height: 55px;
    width: 95%;
    margin-bottom: 4px;
    transition: .3s linear;
}

.post-activities-home-1 .background-animate {
    background: rgb(20, 213, 230);
}

.post-activities-home-2 .background-animate {
    background: rgb(112, 164, 46);
}

.post-activities-home-3 .background-animate {
    background: rgb(181, 133, 18);
}

.post-activities-home-4 .background-animate {
    background: rgb(53, 80, 119);
}

.post-activities-home:hover .background-animate {
    height: calc(100% - 4px);
}

.post-activities-home .background-animate a {
    height: 100%;
    width: 100%;
}

.post-activities-home .article-content {
    position: absolute;
    bottom: 0;
    color: white;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 55px;
}

.post-activities-home .article-content h3 {
    margin: 0;
}

.thumbnail-activities {
    width: 100%;
}

.post-testimonials-home {
    height: 305px;
    overflow: hidden;
}

.post-testimonials-home section {
    position: absolute;
    bottom: 0;
    z-index: 3;
    margin-bottom: -20px;
}

.post-testimonials-home .background-preview {
    position: absolute;
    bottom: 0;
    background: #0f404b;
    mix-blend-mode: multiply;
    height: 170px;
    margin-bottom: -20px;
    width: 100%;
    transition: .3s linear;
    z-index: 3;
}

.testimonials .post-testimonials-home-1 .background-preview {
    background: #2c4547;
}

.testimonials .post-testimonials-home-2 .background-preview {
    background: #407974;
}

.testimonials .post-testimonials-home-3 .background-preview {
    background: #accad2;
}

.slider-stade {
    background: #ffffff;
}

.slider-stade a.read-more {
    background: #175c6b;
    color: #fff;
    text-decoration: none;
    width: 80px;
    padding: 5px;
    transition: .3s all;
}

.slider-stade a.read-more:hover {
    background: #0f404b;
}

.ludwig-stade a.read-more {
    background: #4190a1;
}

.ludwig-stade a.read-more:hover {
    background: #175c6b;
}

.list-other_stades a,
.list-traitements a,
.list-consultations a {
    text-decoration: none;
    color: #2c4547;
    text-transform: uppercase;
    display: block;
    width: 100%;
    height: 100%;
    transition: all .3s;
}

.list-traitements .item-list-intro {
    text-transform: uppercase;
}

.list-traitements .item-list-intro p {
    margin-top: 15px
}

.list-other_stades a h3,
.list-traitements a h3,
.list-consultations a h3 {
    font-family: 'Open Sans Bold';
    font-size: 1em;
    margin-bottom: 0;
}

.list-other_stades a p,
.list-traitements a p,
.list-traitements p,
.list-consultations a p {
    font-family: 'Open Sans Light';
    font-size: .9em;
    margin-top: 0;
}

.list-traitements a span.plus,
.list-consultations a span.plus {
    float: right;
    margin-top: -17px;
    font-size: 1.2em;
}

.list-other_stades a span.plus {
    font-size: 1em;
    float: right;
    margin-top: -28px;
}

.list-traitements .item-list {
    background: #ffffff;
    border-bottom: solid 2px #2c4547;
    transition: all .3s;
}

.list-traitements .item-list:hover {
    background: #133e46;
}

.list-traitements .item-list:hover a {
    color: #ffffff;
}

.list-traitements .item-list-intro:hover {
    color: inherit;
    background: #ffffff;
}

.list-consultations a h3,
.list-consultations a span.plus {
    color: #4190a1;
    transition: all .3s;
}

.list-consultations .item-list {
    background: #ffffff;
    border-bottom: solid 2px #4190a1;
    transition: all .3s;
}

.list-consultations .item-list:hover {
    background: #4190a1;
}

.list-consultations .item-list:hover a,
.list-consultations .item-list:hover a h3,
.list-consultations .item-list:hover a span.plus {
    color: #ffffff;
}

.galerie-avant_apres {
    background: #ffffff;
}

.galerie-avant_apres h3 {
    font-family: 'Open Sans Light';
    text-transform: uppercase
}

.galerie-avant_apres img {
    width: 100%;
}

.list-other_stades .item-list a,
.list-other_stades .item-list a h3,
.list-other_stades .item-list a span.plus {
    color: #ffffff;
}

.list-other_stades .item-list {
    transition: all .2s;
    border-bottom: solid 2px #fff;
}

.list-other_stades .item-list:hover {
    background: #739a1c83;
}

.list-other_stades {
    background: #abc654;
    mix-blend-mode: soft-light;
}

.block-contact {
    background: #454545;
    color: #fff;
    font-family: 'Open Sans Light';
    text-transform: uppercase;
    height: 305px;
}

.block-contact-page {
    height: 350px;
}

.block-contact .nf-field-container {
    margin-bottom: 0 !important;
}

.block-contact .label-above .nf-field-label {
    margin-bottom: 0 !important;
    margin-top: 20px
}

.block-contact-page .label-above .nf-field-label {
    margin-bottom: 0 !important;
    margin-top: 50px
}

.block-contact .label-left .nf-field-label {
    text-align: left;
    width: 23%;
}

.block-contact .label-left .nf-field-element {
    width: 77%;
}

.block-contact .label-left .nf-field-element input {
    border: none;
    height: 20px
}

.block-contact .field-wrap textarea {
    border: none;
    height: 100px
}

.block-contact .ninja-forms-req-symbol,
.block-contact .nf-form-fields-required {
    display: none
}

.block-contact .nf-field-label label {
    font-weight: inherit
}

.block-contact .submit .nf-field-element {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.block-contact .field-wrap input[type="button"] {
    border: none;
    color: #fff;
    background: #454545;
    font-family: 'Open Sans Light';
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1em
}

.block-coordonnee strong {
    font-family: 'Open Sans SemiBold'
}

.block-coordonnee .coordonnee.tel::before {
    content: url(../images/tel2.png);
    margin-right: 8px;
}

.block-coordonnee .coordonnee.mail::before {
    content: url(../images/mail2.png);
    margin-right: 10px;
}

.block-coordonnee .coordonnee.mail {
    text-decoration: none;
    color: inherit
}

.block-coordonnee .coordonnee.mail:hover {
    text-decoration: underline
}

img.take-rdv {
    width: 100%;
    height: auto;
}

.sidebar {
    margin-top: 78px;
    overflow: hidden;
}

.thumbnails-aside {
    width: 100%;
}

.uppercase {
    text-transform: uppercase;
}

.copy a {
    color: inherit;
    text-decoration: none;
}

.localisation {
    align-self: flex-end;
    text-transform: uppercase;
    font-size: 1.1em;
    font-family: 'Open Sans Light'
}

.localisation strong {
    font-family: 'Open Sans SemiBold'
}

.contact-tel {
    color: #fff;
    background: #b58112; 
}

.contact-tel p {
    font-size: 1.2em !important;
    font-family: 'Playfair Bold';
    padding-top: 15px
}

.contact-tel p::before {
    content: '';
    background: #fff;
    width: 90px;
    height: 8px;
    position: absolute;
    margin-top: -15px
}

.contact-tel strong {
    font-size: 1.6em !important;
    line-height: .8em;
    font-family: 'Playfair Black';   
}

.copy a:hover {
    text-decoration: underline;
}

.tease {
    overflow: hidden;
    background: #fff;
}

nav.pagination-block {
    width: 100%;
    display: flex;
    justify-content: center
}

nav.pagination-block ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 33%;
}

nav.pagination-block .btn a,
nav.pagination-block .current span {
    color: #fff;
    background: #b58112;
    border: 2px solid #b58112;
    height: 20px;
    text-decoration: none;
    padding: 5px;
}

nav.pagination-block .disabled button {
    color: #fff;
    background: #c7a253;
    border: 2px solid #c7a253;
    height: 30px;
    padding: 5px;
    margin-top: -10px
}

nav.pagination-block li a {
    color: #b58112;
    background: #fff;
    border: 2px solid #b58112;
    height: 20px;
    text-decoration: none;
    padding: 5px;
    transition: all .2s;
}

nav.pagination-block li a:hover {
    color: #fff;
    background: #b5811288;
    border: 2px solid #b58112;
    height: 20px;
    text-decoration: none;
    padding: 5px;
}

.footer {
    background-color: #2c4547;
    color: #fff;
    height: 200px;
}

.footer p {
    margin: 0;
    font-size: .8em;
}

.footer .container {
    height: 100%;
}

.footer .logo-footer {
    width: 100%
}

/* block 2 columns guntemberg */

.has-2-columns .wp-block-column:nth-child(1) {
    flex-basis: 130%;
    margin-right: 20px;
}

.has-2-columns .wp-block-column:nth-child(1) h2 {
    background: transparent;
}

.has-2-columns .wp-block-column:nth-child(2) {
    margin-left: 10px;
}

.has-2-columns .wp-block-column:nth-child(1) p {
    background: #fff;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 20px 10px 48px;
    font-family: 'Open Sans Light';
}

.has-2-columns .wp-block-column:nth-child(1) p strong {
    font-family: 'Open Sans semiBold';
}

.has-2-columns .wp-block-column:nth-child(1) p a::after {
    font-family: 'Font Awesome';
    content: '\f067';
    float: right;
    font-size: 1.2em;
}

.has-2-columns .wp-block-column:nth-child(1) p a {
    text-decoration: none;
    font-family: 'Open Sans semiBold';
}

.has-2-columns .wp-block-column:nth-child(1) p.has-background {
    color: #fff;
}

.has-2-columns .wp-block-column:nth-child(1) .wp-block-quote {
    width: 100%;
    font-size: 1em;
    line-height: 1.4em;
}

.has-2-columns .wp-block-column:nth-child(1) .wp-block-quote p {
    font-family: 'Open Sans semiBold';
    color: rgb(188, 133, 45);
}

.has-2-columns .wp-block-column:nth-child(1) .wp-block-quote p::before {
    content: url(../images/warning.png);
    position: absolute;
    margin-left: -40px;
    margin-top: 2px;
}

.has-2-columns .wp-block-column:nth-child(1) p.artas::before {
    content: url(../images/artas.png);
    position: absolute;
    margin-left: -40px;
    margin-top: 36px;
}

.has-2-columns .wp-block-column:nth-child(1)::after {
    content: '';
    width: 43px;
    height: 10px;
    /* margin-top: 14px;
    margin-left: -48px; */
    background: #2c4547;
}

.has-2-columns:nth-child(1),
.has-2-columns:nth-child(1) h2 {
    color: rgb(106, 151, 44);
}

.has-2-columns:nth-child(1) h2::before,
.has-2-columns:nth-child(1) p.has-background {
    background: rgb(106, 151, 44);
}

.has-2-columns:nth-child(2),
.has-2-columns:nth-child(2) h2,
.has-2-columns:nth-child(5),
.has-2-columns:nth-child(5) h2 {
    color: rgb(33, 66, 68);
}

.has-2-columns:nth-child(2) h2::before, 
.has-2-columns:nth-child(2) p.has-background,
.has-2-columns:nth-child(5) h2::before, 
.has-2-columns:nth-child(5) p.has-background {
    background: rgb(33, 66, 68);
}

.has-2-columns:nth-child(3),
.has-2-columns:nth-child(3) h2 {
    color: rgb(54, 102, 106);
}

.has-2-columns:nth-child(3) h2::before, 
.has-2-columns:nth-child(3) p.has-background {
    background: rgb(54, 102, 106);
}

.has-2-columns:nth-child(4),
.has-2-columns:nth-child(4) h2 {
    color: rgb(188, 133, 45);
}

.has-2-columns:nth-child(4) h2::before, 
.has-2-columns:nth-child(4) p.has-background {
    background: rgb(188, 133, 45);
}

.article-body p,
.article-body ul,
.article-body h2,
.article-body h3,
.article-body h4,
.article-body h5,
.article-body h6 {
    background: #fff;
    font-family: 'Open Sans Light';
    text-transform: uppercase;
    padding: 20px;
    padding-left: 48px;
    margin: 0;
}
 
.article-body ul {
    padding-left: 68px;
}

aside.contact-link {
    position: fixed;
    top: 200px;
    right: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 6;
}

aside.contact-link .contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #b58112;
    padding: 5px 3px;
    width: 35px;
    margin: 2px 0;
    transition: all .3s;
} 

aside.contact-link .social-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    padding: 5px 3px;
    width: 35px;
} 

aside.contact-link img {
    max-width: 25px;
    max-height: 25px;
    transition: all .3s;
}

aside.contact-link img:hover {
    opacity: .7;
}

aside.contact-link .contact-item.tel {
    width: 180px;
    right: -145px;
    margin-top: -30px;
    padding-left: 5px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    text-decoration: none;
} 

aside.contact-link .contact-item.tel:hover {
    right: 0;
}

.wp-block-embed-vimeo iframe {
    height: 415px;
    width: 100%;
}

.category-faq:nth-child(1) {
    color: #abc654;
}

.category-faq:nth-child(1) h2 {
    color: #abc654;
}

.category-faq:nth-child(1) h2::before, .category-faq:nth-child(1) .underline-question {
    background: #abc654;
}

.category-faq:nth-child(1) .content-reponse-faq {
    border-left-color: #abc654;
}

.category-faq:nth-child(2) {
    color: #2c4547;
}

.category-faq:nth-child(2) h2 {
    color: #2c4547;
}

.category-faq:nth-child(2) h2::before, .category-faq:nth-child(2) .underline-question {
    background: #2c4547;
}

.category-faq:nth-child(2) .content-reponse-faq {
    border-left-color: #2c4547;
}

.category-faq:nth-child(3) {
    color: #4190a1;
}

.category-faq:nth-child(3) h2 {
    color: #4190a1;
}

.category-faq:nth-child(3) h2::before, .category-faq:nth-child(3) .underline-question {
    background: #4190a1;
}

.category-faq:nth-child(3) .content-reponse-faq {
    border-left-color: #4190a1;
}

.category-faq:nth-child(4) {
    color: #175c6b;
}

.category-faq:nth-child(4) h2 {
    color: #175c6b;
}

.category-faq:nth-child(4) h2::before, .category-faq:nth-child(4) .underline-question {
    background: #175c6b;
}

.category-faq:nth-child(4) .content-reponse-faq {
    border-left-color: #175c6b;
}

.block-faq,
.category-faq,
.block-faq .arrow {
    transition: .5s all;
}

.question-faq, .reponse-faq {
    width: 100%;
    padding: 0 48px;
    background: #ffffff;
}

.underline-question {
    content: '';
    position: absolute;
    width: 43px;
    height: 5px;
    align-self: flex-end;
}

.question-faq h3 {
    font-family: "Open Sans SemiBold";
    text-transform: uppercase;
    font-size: .9em;
    margin: 20px 0;
}

.question-faq span.arrow {
    font-size: 3em;
}

.question-faq {
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reponse-faq {
    display: none;
    position: absolute;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
}

.reponse-faq .content-reponse-faq {
    border-left: 4px solid;
    padding-left: 20px;
    font-family: 'Open Sans SemiBold';
    font-family: .9em;
    line-height: 1.1em;
    text-transform: uppercase
}

.block-adresse {
    background: url(../images/bg-pont.png);
    background-position-y: bottom;
    background-size: cover;
}

.maps {
    background: url(../images/maps.png);
    background-size: cover;
    background-position: center;
    height: 350px;
}