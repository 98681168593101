/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .header-home {
        height: 250px;
    }

    .sidebar {
        margin-top: 20px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    h1, .h1 {
        font-size: 2.3em;
    }

    .post-testimonials-home .background-preview {
        margin-bottom: 0;
        height: 100px;
    }
    
    .testimonials .preview a {
        margin-top: -10px;
        margin-bottom: 0;
    }

    .post-testimonials-home section {
        margin-bottom: 0;
    }
    
    .header-home,
    .header-content-home {
        height: 400px;
    }

    .menu-main ul li:nth-last-child(1) {
        margin-right: 17px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .sidebar {
        margin-top: 78px;
    }

    .menu-main ul li:nth-last-child(1) {
        margin-right: 20px;
    }

    .header-picture .header-slider {
        margin-top: -150px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .menu-main ul li:nth-last-child(1) {
        margin-right: 30px;
    }
}