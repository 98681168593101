.menu-main {
    position: absolute;
    width: 100%;
}

.menu-main-bg {
    position: absolute;
    min-height: 75px;
    width: 101.5%;
    right: 0;
    mix-blend-mode: multiply;
    transition: all .4s;
}

.menu-main ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    text-transform: uppercase;
    min-height: 65px;
    padding: 30px 7px 10px 0;
    margin: 0;
}

.menu-main ul ul,
.second-menu-main ul ul {
    display: none;
    transition: all .3s;
}

.menu-main ul li a {
    text-decoration: none;
    color: white;
    font-size: .75em;
    font-family: 'Open Sans Bold';
    text-align: right;
    transition: all .3s
}

.menu-main a:hover {
    opacity: .7;
}

.menu-main li ul .menu-item-has-children a {
    color: #53b1bc;
    font-family: 'Open Sans Bold';
    padding-right: 0;
}

.menu-main li ul li a {
    color: #ffffff;
    font-family: 'Open Sans Light';
    padding-right: 3px;
}

.menu-main li ul .menu-item-has-children ul a {
    color: inherit;
    font-family: 'Open Sans Light';
}

.menu-main li ul li a::after {
    content: '';
}

.menu-main ul a.rotate::after  {
    margin-left: 5px;
    font-family: 'Font Awesome';
    content: '\f0d7' !important;
    font-size: 1.3em;
}

.menu-main li ul .menu-item-has-children ul a::after {
    content: '';
}

.menu-main ul li a::after, 
.menu-main li ul .menu-item-has-children a::after {
    margin-left: 5px;
    font-family: 'Font Awesome';
    content: '\f0da';
    font-size: 1.3em;
}

.menu-main ul li:nth-last-child(1) {
    position: absolute;
    right: 0;
    margin-right: 30px;
    margin-top: 25px;
}

.menu-main ul li {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all .4s;
}

.menu-main li ul li {
    margin-left: 0;
}

.menu-main li ul {
    padding: 15px 0;
    position: absolute;
}

.menu-main li ul li ul{
    padding: 0;
    position: relative;
}

.menu-main li ul li:nth-last-child(1) {
    position: relative;
    padding: 0;
    margin: 0;
}

/* Second navBar */

#second-nav-main {
    position: fixed;
    top: -70px;
    display: block;
    transition: top 0.3s;
    z-index: 6;
}

.second-menu {
    width: 100%;
    height: 70px;
    background: #fff;
    border-bottom: 5px solid #2c4547;
    position: absolute;
    top: 0;
    transition: all .5s;
}

.second-menu-main ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    text-transform: uppercase;
}

.second-menu-main ul li a {
    text-decoration: none;
    color: #7c7c7c;
    font-family: 'Open Sans Bold';
    transition: all .3s
}

.second-menu-main ul .menu-item-has-children a::after,
.second-menu-main ul .menu-item-has-children:hover ul .menu-item-has-children a::after {
    margin-left: 5px;
    font-family: 'Font Awesome';
    content: '\f0da';
    font-size: 1.3em;
}

.second-menu-main ul .menu-item-has-children:hover a::after {
    margin-left: 0;
    font-family: 'Font Awesome';
    content: '\f0d7' !important;
    font-size: 1.3em;
}

.second-menu-main ul li ul {
    display: none;
    position: absolute;
    background: white;
    width: 200px;
    padding: 5px;
}

.second-menu-main ul .menu-item-has-children:hover ul a::after {
    content: '' !important;
}

.second-menu-main ul li:hover ul li ul {
    display: none;
}

.second-menu-main ul li ul li:hover ul {
    display: block;
    position: absolute;
    margin-left: 100px;
    top: 0;
}

#searchform {
    position: absolute;
    width: 100%;
    z-index: 5;
}

#searchform input,
#searchform button {
    border: none;
    padding: 0;
    color: #0897a4;
    height: 20px;
    background: #fff;
}

#searchform input {
    padding-left: 10px !important;
}

#searchform button img {
    height: 100%
}

#searchform button {
    width: 29px;
    margin: 0;
}

.second-logo {
    height: 60px
}

/* Burger */

#menuToggle a {
    text-decoration: none;
    color: #232323;
    transition: color 0.3s ease;
}

#menuToggle a:hover {
    color: tomato;
}

#menuToggle {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px; 
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu-burger {
    position: absolute;
    width: 0;
    margin: -100px 0 0 0;
    padding: 125px 20px 50px 20px;
    right: -20px;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 100% 100%;
    transform: scale(0, 1);
    transition: linear 0.3s;
    overflow: hidden;
}

#menu-burger ul {
    width: 260px;
    padding-left: 20px;
    list-style-type: none;
}

#menu-burger li {
    padding: 10px 0;
    font-size: 1em;
}

/*
 * And let's fade it in from the left
 */
#menuToggle input:checked ~ #menu-burger {
    transform: scale(1.0, 1.0);
    opacity: 1;
    width: 300px;
}