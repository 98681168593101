.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {
    background: #175c6b;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}

.echelle-slider {
    width: 98%;
}

.echelle-slider#ludwig .slick-dots {
    background: #4190a1;
}

.slick-dots li {
    width: auto;
    height: 25px;
    margin: 0;
    border-left: solid 1px white;
}

.slick-dots li button::before {
    font-family: 'Playfair Bold';
    font-size: 12px;
    width: 40px;
    height: 25px;
    content: 'Stade';
    text-align: center;
    opacity: 1;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-dots li button {
    font-family: 'Playfair Bold';
    font-size: 12px;
    width: 100px;
    height: 25px;
    text-align: right;
    padding: 0 30px;
}

.slick-dots li button,
.slick-dots li button::before {
    color: #ffffff;
    transition: .1s;
}

.slick-dots li.slick-active button,
.slick-dots li.slick-active button::before {
    background: #ffffff;
    color: #175c6b;
    opacity: 1;
}

.slick-dots li:hover button,
.slick-dots li:hover button::before {
    background: #ffffff;
    color: #175c6b;
}

.echelle-slider#ludwig .slick-dots li.slick-active button,
.echelle-slider#ludwig .slick-dots li.slick-active button::before {
    background: #ffffff;
    color: #4190a1;
    opacity: 1;
}

.echelle-slider#ludwig .slick-dots li:hover button,
.echelle-slider#ludwig .slick-dots li:hover button::before {
    background: #ffffff;
    color: #4190a1;
}

.slick-arrow {
    top: inherit;
    bottom: 0;
    z-index: 6;
    height: 25px;
    transform: translate(-30px, 0);
}

.slick-next::before {
    content: '\f105';
    opacity: 1;
    font-family: 'Font Awesome';
}

.slick-prev::before {
    content: '\f104';
    opacity: 1;
    font-family: 'Font Awesome';
}

.arrow-group {
    position: absolute;
    right: 0;
    bottom: 0;
}

.slick-slide img {
    height: 100%;
}