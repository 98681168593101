@font-face {
    font-family: "Open Sans";
    src: url("../fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "Open Sans Light";
    src: url("../fonts/Open_Sans/OpenSans-Light.ttf");
}

@font-face {
    font-family: "Open Sans Bold";
    src: url("../fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
    font-family: "Open Sans ExtraBold";
    src: url("../fonts/Open_Sans/OpenSans-ExtraBold.ttf");
}

@font-face {
    font-family: "Open Sans SemiBold";
    src: url("../fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
    font-family: "Open Sans Italic";
    src: url("../fonts/Open_Sans/OpenSans-Italic.ttf");
}

@font-face {
    font-family: "Open Sans LightItalic";
    src: url("../fonts/Open_Sans/OpenSans-LightItalic.ttf");
}

@font-face {
    font-family: "Open Sans BoldItalic";
    src: url("../fonts/Open_Sans/OpenSans-BoldItalic.ttf");
}

@font-face {
    font-family: "Open Sans ExtraBoldItalic";
    src: url("../fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: "Open Sans SemiBoldItalic";
    src: url("../fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf");
}

@font-face {
    font-family: "Playfair";
    src: url("../fonts/Playfair_Display/PlayfairDisplay-Regular.ttf");
}

@font-face {
    font-family: "Playfair Black";
    src: url("../fonts/Playfair_Display/PlayfairDisplay-Black.ttf");
}

@font-face {
    font-family: "Playfair Bold";
    src: url("../fonts/Playfair_Display/PlayfairDisplay-Bold.ttf");
}

@font-face {
    font-family: "Playfair Italic";
    src: url("../fonts/Playfair_Display/PlayfairDisplay-Italic.ttf");
}

@font-face {
    font-family: "Playfair BlackItalic";
    src: url("../fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf");
}

@font-face {
    font-family: "Playfair BoldItalic";
    src: url("../fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("../fonts/FontAwesome/fa-solid-900.eot");
    src: url("../fonts/FontAwesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-solid-900.woff2") format("woff2"), url("../fonts/FontAwesome/fa-solid-900.woff") format("woff"), url("../fonts/FontAwesome/fa-solid-900.ttf") format("truetype"), url("../fonts/FontAwesome/fa-solid-900.svg#fontawesome") format("svg");
}